.pageContent {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;

    .mainContent {
        max-width: 1200px;

        p,
        h3,
        li,
        h1 {
            color: #e6e8ec;
        }

        blockquote {
            background-color: dimgray;
        }
    }
}
